import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { ethers } from "ethers";
import { StackAbi, StackAddress, StakeAbi, StakeAddress, deodToken, deodTokenABI, getClaim, getClaimABI, nefiPro, nefiProABI, nefiSwapper, nefiSwapperABI, nefiToken, nefiTokenABI, nefiproToken, nefiproTokenABI, salev1, salev1ABI } from "../Abi/Content";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import UserPageSection from "./Content";
import Loading from "../LoaderPageSection/Loading";
import deodLogo from "../../../image/Logo.png";
import UsdtLogo from "../../../image/tether-logo.png";
import { Oval } from "react-loader-spinner";
const UserDashboard2 = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [refferal, setRefferal] = useState("");
  const [totalToken, setTotalToken] = useState("0");
  const [totalClaimToken, setTotalClaimToken] = useState("0");
  const [availableClaim, setAvailableClaim] = useState("0");
  const [refferalEarn, setRefferalEarn] = useState("0");
  const [stackingData, setStackingData] = useState([]);
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [userWithdrawnUSDTTokens, setuserWithdrawnUSDTTokens] = useState(null)
  const [userWithdrawnTokens, setuserWithdrawnTokens] = useState(null)
  const [userAvailableTokens, setuserAvailableTokens] = useState(null)
  const [totalSAFXTokensPurchased, settotalSAFXTokensPurchased] = useState(null)
  const [getUserReferralEarning, setgetUserReferralEarning] = useState(null)
  const [getUsdtReferalEarning, setgetUsdtReferalEarning] = useState(null)
  const [getCurrentNefiPrice, setgetCurrentNefiPrice] = useState(0)
  const [userDeodReceived, setuserDeodReceived] = useState(0)
  const [deodStaked, setdeodStaked] = useState(0)
  const [usertotalsell, setusertotalsell] = useState(0)
  const [usertotalbuy, setusertotalbuy] = useState(0)
  const [unclaimedNefiTokens, setunclaimedNefiTokens] = useState(0)
  const [totalDeodStaked, settotalDeodStaked] = useState(0)
  const [claimedNefiTokens, setclaimedNefiTokens] = useState(0)
  const [referralRewards, setreferralRewards] = useState(0)
  const [buyValue, setbuyValue] = useState(0)
  const [claimValue, setclaimValue] = useState(0)
  const [sellValue, setsellValue] = useState(0)
  const [deodbuyValue, setdeodbuyValue] = useState(0)
  const [deodclaimValue, setdeodclaimValue] = useState(0)
  const [deodsellValue, setdeodsellValue] = useState(0)
  const [deodBalance, setDeodBalance] = useState(0)
  const [priority, setpriority] = useState(false)
  const [isResponseTrue, setIsResponseTrue] = useState(false)
  const [loadText, setloadText] = useState('')
  const [totalSupply, settotalSupply] = useState(0)
  const [balanceofcontract, setBalanceofcontract] = useState(0)
  const [swapValue, setswapValue] = useState(0)
  const [userNefiBalance, setuserNefiBalance] = useState(0)
  const [getTotalNefiClaimed, setgetTotalNefiClaimed] = useState(0)
  // const [totalDeodStaked,settotalDeodStaked] = useState(0)
  const [circulatingNefiSupply, setcirculatingNefiSupply] = useState(0)
  const [userprioritystatus, setuserprioritystatus] = useState(false)
  const [nefiUsdPrice, setnefiUsdPrice] = useState(0)
  const [priorityCount, setpriorityCount] = useState(0)
  const targetDate = new Date('2024-11-25T12:30:00').getTime();
  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());
  const [usernewNefiBalance, setusernewNefiBalance] = useState('');
  let getLocalData = sessionStorage.getItem("auth");
  getLocalData = JSON.parse(getLocalData);
  const token2 = sessionStorage.getItem('token2')

  // const networkId = 137  //mainnet
  const networkId = 56  //testnet
  //    /*Switch to mainnet*/  const networkData = [
  //         {
  //             chainId: ethers.utils.hexlify(networkId),
  //             rpcUrls: ["https://polygon-rpc.com"],

  //             chainName: "Polygon Mainnet",
  //             nativeCurrency: {
  //                 name: "MATIC",
  //                 symbol: "MATIC", // 2-6 characters long
  //                 decimals: 18,
  //             },
  //             blockExplorerUrls: ["https://polygonscan.com/"],
  //         },
  //     ]
      /*Switch to Testnet*/  const networkData = [
    {
      chainId: networkId,
      rpcUrls: ["https://bsc-dataseed.binance.org/"],

      chainName: "BNB Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB", // 2-6 characters long
        decimals: 18,
      },
      blockExplorerUrls: ["https:/https://bscscan.com/"],
    },
  ]
  const switchNetworks = async () => {

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexlify(networkId) }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: networkData
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }

  }
  const loadData = async () => {
    // setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      if (network.chainId !== networkId) {
        await switchNetworks();
      }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAddress(address)
      const oldnefiContract = new ethers.Contract(nefiToken, nefiTokenABI, signer)
      const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
      const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)
      const nefitokenContract = new ethers.Contract(nefiproToken, nefiproTokenABI, signer)
      const balanceOfnewNefi = await nefitokenContract.balanceOf(address)
      setusernewNefiBalance(parseFloat(balanceOfnewNefi) / 10 ** 18)

      const balanceOf = await deodContract.balanceOf(address)
      setDeodBalance(parseFloat(balanceOf) / 10 ** 18)
      const balanceOfNefi = await oldnefiContract.balanceOf(address)
      setuserNefiBalance(parseFloat(balanceOfNefi) / 10 ** 18)
      const getCurrentNefiPrice = await nefiContract.getCurrentNefiPrice()
      setgetCurrentNefiPrice(parseFloat(getCurrentNefiPrice) / 10 ** 18)

      const usertotalsell = await nefiContract.usertotalsell(address)
      setusertotalsell(parseFloat(usertotalsell) / 10 ** 18)

      const usertotalbuy = await nefiContract.usertotalbuy(address)
      setusertotalbuy(parseFloat(usertotalbuy) / 10 ** 18)

      const unclaimedNefiTokens = await nefiContract.unclaimedNefiTokens(address)
      const lilNefi = parseFloat(unclaimedNefiTokens) / 10 ** 18
      if (lilNefi - 0.001 < 0) {
        setunclaimedNefiTokens(0)
      } else {
        setunclaimedNefiTokens(lilNefi - 0.001)
      }

      const claimedNefiTokens = await nefiContract.claimedNefiTokens(address)
      setclaimedNefiTokens(parseFloat(claimedNefiTokens) / 10 ** 18)

      const referralRewards = await nefiContract.referralRewardsAccumulated(address)
      setreferralRewards(parseFloat(referralRewards) / 10 ** 18)

      const userDeodReceived = await nefiContract.userDeodReceived(address)
      setuserDeodReceived(parseFloat(userDeodReceived) / 10 ** 18)
      const deodStaked = await nefiContract.deodStaked(address)
      setdeodStaked(parseFloat(deodStaked) / 10 ** 18)
      const totalDeodStaked = await nefiContract.totalDeodStaked()
      settotalDeodStaked(parseFloat(totalDeodStaked) / 10 ** 18)
      const circulatingNefiSupply = await nefiContract.circulatingNefiSupply()
      setcirculatingNefiSupply(parseFloat(circulatingNefiSupply) / 10 ** 18)
      const deodVal = parseFloat(((+getCurrentNefiPrice)) + ((+getCurrentNefiPrice) * 0.425645)) / 10 ** 18
      // const usdtnefiPrice = deodVal
      const resp = await axios('https://backend.nexdefi.ai/deodprice');
      const nefiUsdPrice = (resp.data.usdPrice) * +deodVal
      // console.log((resp.data.priceRoute.destUSD) * +deodVal,'usd')
      setnefiUsdPrice(nefiUsdPrice)
      // const totalSupply = await nefiContract.totalSupply()
      // settotalSupply(parseFloat(totalSupply) / 10 ** 18)
      // const balanceofcontract = await nefiContract.balanceOf(nefiPro)
      // setBalanceofcontract(parseFloat(balanceofcontract) / 10 ** 18)
      // let totalAmountCount = await StakeContract.userDepositCounts(address);
      // totalAmountCount = parseInt(totalAmountCount);

      // for (let index = 0; index < totalAmountCount; index++) {
      //   let totalAmount = await StakeContract.userDepositIds(address, index);
      //   totalAmount = parseInt(totalAmount);

      //   let totaldepositInfo = await StakeContract.depositInfo(totalAmount);
      //   // totaldepositInfo = parseFloat(totaldepositInfo);
      //   console.log("totaldepositInfo************", totaldepositInfo);
      // }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    // setLoading(false);
  };
  const getNefiPrice = async () => {
    try {
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAddress(address)
      const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
      const nefigetClaimcontract = new ethers.Contract(getClaim, getClaimABI, signer)
      const getTotalNefiClaimed = await nefigetClaimcontract.getTotalNefiClaimed()
      setgetTotalNefiClaimed(parseFloat(getTotalNefiClaimed) / 10 ** 18)
      const getCurrentNefiPrice = await nefiContract.getCurrentNefiPrice()
      setgetCurrentNefiPrice(parseFloat(getCurrentNefiPrice) / 10 ** 18)
      const totalDeodStaked = await nefiContract.totalDeodStaked()
      settotalDeodStaked(parseFloat(totalDeodStaked) / 10 ** 18)
      const circulatingNefiSupply = await nefiContract.circulatingNefiSupply()
      setcirculatingNefiSupply(parseFloat(circulatingNefiSupply) / 10 ** 18)
      const deodVal = parseFloat(((+getCurrentNefiPrice)) + ((+getCurrentNefiPrice) * 0.425645)) / 10 ** 18
      // const usdtnefiPrice = deodVal
      const resp = await axios('https://backend.nexdefi.ai/deodprice');
      const nefiUsdPrice = (resp.data.usdPrice) * +deodVal
      // console.log((resp.data.priceRoute.destUSD) * +deodVal,'usd')
      setnefiUsdPrice(nefiUsdPrice)
      // const balanceofcontract = await nefiContract.balanceOf(nefiPro)
      // setBalanceofcontract(parseFloat(balanceofcontract) / 10 ** 18)
    } catch (error) {
      console.log(error)
    }

  }
  const [timerLeft, setTimerLeft] = useState(0); // Initialize with 60 seconds
  const [isActive, setIsActive] = useState(false); // Track if timer is active

  useEffect(() => {
    let timer;

    if (isActive && timerLeft > 0) {
      timer = setInterval(() => {
        setTimerLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timerLeft === 0) {
      clearInterval(timer);
      setIsActive(false); // Stop timer when it reaches zero
    }

    // Cleanup the interval on component unmount or when the timer stops
    return () => clearInterval(timer);
  }, [isActive, timerLeft]);

  const startTimer = () => {
    setTimerLeft(30); // Reset to 60 seconds
    setIsActive(true); // Start the timer
  };
  const handleClaimAPI = async (trxHash) => {

    const token = sessionStorage.getItem("token2");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const bodyData = {
      "deodAmount": deodclaimValue,
      "rate": getCurrentNefiPrice,
      "nefiAmount": claimValue,
      "transactionHash": trxHash
    };
    try {
      const resp = await axios.post(`/nefi-pro/claim-token`, bodyData, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // window.location.reload(false);
          loadData()
          // startTimer()
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleBuyApi = async (trxHash) => {
    const token = sessionStorage.getItem("token2");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const bodyData = {
      "deodAmount": deodbuyValue,
      "rate": getCurrentNefiPrice,
      "nefiAmount": buyValue,
      "transactionHash": trxHash
    };
    try {
      const resp = await axios.post(`/api/regular-purchase`, bodyData, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // window.location.reload(false);
          loadData()
          startTimer()
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  }
  const handleSellApi = async (trxHash) => {
    const token = sessionStorage.getItem("token2");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const api = `/nefi-pro/sale-token`
    const payload = {
      "deodAmount": deodsellValue,
      "rate": getCurrentNefiPrice,
      "nefiAmount": sellValue,
      "transactionHash": trxHash
    }
    const resp = await axios.post(api, payload, {
      headers,
    });
    if (resp.status == 200 || resp.status == 201) {
      toast.success('Nefi Sold SuccessFully')
    }
  }
  const handleClaim = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const storeAddress = auth?.user?.address;
      if (address === storeAddress) {
        const StakeContract = new ethers.Contract(
          StakeAddress,
          StakeAbi,
          signer
        );
        const txn = await StakeContract.withdrawTokens(address);
        const receipttxn1 = await txn.wait();
        if (receipttxn1.status !== 1) {
          toast.error("Error");
        } else {
          const hash = await receipttxn1.transactionHash;
          await handleClaimAPI(hash);
        }
      } else {
        toast.error("Connect correct wallet");
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
      toast.error(`${JSON.stringify(error.reason)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
    window.location.reload(false);
  };

  const handleUnitChangeBuy = (e) => {
    const unitValue = e.target.value;
    setdeodbuyValue(e.target.value)
    setbuyValue(((unitValue / getCurrentNefiPrice) - (unitValue / getCurrentNefiPrice * 0.3)).toFixed(3));
  };
  const handleUnitChangeSell = (e) => {
    let unitValue;
    if (typeof e === "object") {
      console.log('object')
      unitValue = e.target.value;
    }
    else {
      unitValue = e - 0.001
    }
    if (unitValue < 0) {
      unitValue = 0
    }
    setsellValue(unitValue.toString())
    setdeodsellValue(((unitValue * getCurrentNefiPrice) - (unitValue * getCurrentNefiPrice * 0.15)).toFixed(3));
  };
  const handleUnitChangeClaim = (e) => {
    const unitValue = e.target.value;
    setclaimValue(e.target.value)
    setdeodclaimValue((unitValue * getCurrentNefiPrice).toFixed(3));
  };
  const handleUnitChangeSwap = (e) => {
    const unitValue = e.target.value;
    setswapValue(e.target.value)
    // setdeodclaimValue((unitValue * getCurrentNefiPrice).toFixed(3));
  }
  const handlesubUnitChangeBuy = (e) => {
    const unitValue = e.target.value;
    setdeodbuyValue((unitValue * getCurrentNefiPrice).toFixed(3));
    setbuyValue(e.target.value)
  };
  const handlesubUnitChangeSell = (e) => {
    const unitValue = e.target.value;
    setsellValue(((unitValue / (getCurrentNefiPrice)) + (unitValue / (getCurrentNefiPrice * 0.85)) * 0.15).toFixed(3));
    setdeodsellValue(e.target.value)
  };
  const handlesubUnitChangeClaim = (e) => {
    const unitValue = e.target.value;
    // setclaimValue(e.target.value)
    // setdeodclaimValue((unitValue / getCurrentNefiPrice).toFixed(6));
    setdeodclaimValue(e.target.value)
    setclaimValue((unitValue / getCurrentNefiPrice).toFixed(3));
  };

  const calculateBuyPercentage = (perc) => {
    const numb = (deodBalance * perc) / 100;
    if (deodBalance >= 50000) {
      setdeodbuyValue("50000")
      setbuyValue(((50000 / getCurrentNefiPrice) - (50000 / getCurrentNefiPrice * 0.3)).toFixed(3).toString())

    } else {
      alert('Insufficient Balance')
    }
  };
  const calculateClaimPercentage = (perc) => {
    const numb = (unclaimedNefiTokens * perc) / 100;
    setclaimValue((numb - 0.001).toFixed(3).toString())
  };
  const calculateSellPercentage = (perc) => {
    const numb = (unclaimedNefiTokens * perc) / 100;
    handleUnitChangeSell(numb)
    // setsellValue(numb.toFixed(4).toString())
  };

  const checkStatus = () => {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        setLoading(true);
        setloadText('Transaction Queued')

        if (userprioritystatus) {
          clearInterval(intervalId); // Stop checking when status is true
          resolve(true);             // Resolve the promise to proceed
        }
      }, 2000); // Check every second
    });
  };

  function generateUniqueText(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }
  const checkUserStatus = async () => {
    const token = sessionStorage.getItem('token2')
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const intervalId = setInterval(async () => {
      try {
        setLoading(true);
        setloadText('Transaction Queued')
        const checkregularStatusApi = `/nefi-pro/regular-user-status`
        const resp = await axios(checkregularStatusApi, {
          headers,
        })
        console.log(resp.data.canProceed)
        if (resp.data.canProceed) {
          setIsResponseTrue(true);
          clearInterval(intervalId);
          return true
        }
      } catch (error) {
        console.error('Error calling API:', error);
      }
    }, 5000);
  }

  const priorityAddress = [
    `0xe0e67b84d34d279a50fabef34f44e9b54ec0e9b5`,
    `0x00c887d3bdad5280d5012370913a6a477d9066ce`,
    `0xa317d8018e68871918f474f2042fba50cd75c844`,
    `0xd2c888a5e95bdc341b8f5679068655df5da36ef4`,
    `0x0605e6a1eead9ac175879a5757eba687df5ee6a4`,
    `0xd00297d439a3cddd2ee2efd1aff3180d2114a80f`,
    `0x9309e5768fb56de1c5a99636798d3aeee2dbc447`,
    `0x314d4fac29670fe29d2af489aed220cf7d2a9a34`,
    `0xfeeecb472330ccbf2253105bbf66559d2c1277ad`,
    `0xf60ca50c77828730440fb6d83e53ccf1c2c7b320`,
    `0x8a429a20fc53893236128dc16f380f0da366b6f1`,
  ]

  const handleBuyNefi = async () => {
    // alert("Coming Soon")
    try {
      
      if ( +deodBalance < +deodbuyValue ) {
        toast.error("Insufficient Balance");
        return; // Exit the function early if the value is invalid
      }

      if ( +deodbuyValue <= 0 || +deodbuyValue > 50000) {
        toast.error("Invalid Amount. Amount must be greater than 0 and less than 50,000.");
        return; // Exit the function early if the value is invalid
      }

      const token = sessionStorage.getItem('token2')
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You can adjust this as needed
      };
      if (token) {
        const isPriority = true
        // console.log(isPriority,'is priority')

        if (isPriority) {
          setLoading(true);
          setloadText('Transaction Processing')
          const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
          const signer = newProvider.getSigner();
          const currentaccount = await signer.getAddress();
          const address = currentaccount.toLowerCase();
          const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
          const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)
          try {
            // approve deod
            debugger
            const allowance = await deodContract.allowance(address, nefiPro)
            const allowanceOf = (parseFloat(allowance) / 10 ** 18)
            console.log(allowanceOf, +deodbuyValue, 'allowanceOf')
            if (+allowanceOf > +deodbuyValue) {
              const buyTx = await nefiContract.buyNefi(ethers.utils.parseEther(deodbuyValue))
              const buyRcpt = await buyTx.wait()

              if (buyRcpt.status === 1) {
                const payload2 = {
                  "deodAmount": deodbuyValue,
                  "rate": getCurrentNefiPrice,
                  "nefiAmount": buyValue,
                  "transactionHash": buyRcpt.transactionHash
                }
                setLoading(true);
                setloadText('Completing Transaction')
                const priorityApi = `/nefi-pro/regular-purchase`
                const resp = await axios.post(priorityApi, payload2, {
                  headers,
                })
                console.log(resp)
                if (resp.status == 201 || resp.status == 200) {
                  toast.success('Transaction Successful')
                }
              }
            } else {
              const approveTx = await deodContract.approve(nefiPro, ethers.utils.parseEther(deodbuyValue))
              const aprRcpt = await approveTx.wait()
              //buy NEFI
              if (aprRcpt.status === 1) {

                const buyTx = await nefiContract.buyNefi(ethers.utils.parseEther(deodbuyValue))
                const buyRcpt = await buyTx.wait()

                if (buyRcpt.status === 1) {
                  const payload2 = {
                    "deodAmount": deodbuyValue,
                    "rate": getCurrentNefiPrice,
                    "nefiAmount": buyValue,
                    "transactionHash": buyRcpt.transactionHash
                  }
                  setLoading(true);
                  setloadText('Completing Transaction')
                  const priorityApi = `/nefi-pro/complete-purchase`
                  const resp = await axios.post(priorityApi, payload2, {
                    headers,
                  })
                  console.log(resp)
                  if (resp.status == 201 || resp.status == 200) {
                    toast.success('Transaction Successful')
                  }
                }
              }
            }

          } catch (error) {
            console.log(error)
            const priorityrejectApi = `/nefi-pro/trx-reject-priority-user`
            const payload = {
              "status": "rejected",
            }
            const resp = await axios.post(priorityrejectApi, payload, {
              headers,
            })
            toast.error('Transaction Rejected')
          }


        } else {
          let canProceed = false; // Initial flag to control the loop
          let ispriorityCount = false; // Initial flag to control the loop
          // Loop until the API returns true
          while (!ispriorityCount) {
            try {
              setLoading(true);
              setloadText('Checking Status...');

              // Call your API to check the status
              const checkpurchaseStatusApi = `/nefi-pro/count-purchase`;
              const resp = await axios(checkpurchaseStatusApi, {
                headers,
              });

              console.log(resp.data.count);

              if (resp.data.count >= 1) {
                ispriorityCount = true; // Exit the loop when the response is true
              } else {
                console.log('Can\'t proceed yet, retrying in 5 seconds...');
                // Wait for 5 seconds before making the next call
                await new Promise(resolve => setTimeout(resolve, 5000));
              }
            } catch (error) {
              console.error('Error calling API:', error);
              setLoading(false);
              break; // Exit the loop if there's an error
            }
          }
          if (ispriorityCount) {
            while (!canProceed) {
              try {
                setLoading(true);
                setloadText('Transaction Queued...');

                // Call your API to check the status
                const checkregularStatusApi = `/nefi-pro/regular-user-status`;
                const resp = await axios(checkregularStatusApi, {
                  headers,
                });

                console.log(resp.data.canProceed);

                if (resp.data.canProceed) {
                  canProceed = true; // Exit the loop when the response is true
                } else {
                  console.log('Can\'t proceed yet, retrying in 5 seconds...');
                  // Wait for 5 seconds before making the next call
                  await new Promise(resolve => setTimeout(resolve, 5000));
                }
              } catch (error) {
                console.error('Error calling API:', error);
                setLoading(false);
                break; // Exit the loop if there's an error
              }
            }
            if (canProceed) {
              setLoading(true);
              setloadText('Transaction Processing')
              const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
              const signer = newProvider.getSigner();
              const currentaccount = await signer.getAddress();
              const address = currentaccount.toLowerCase();
              const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
              const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)
              try {
                // approve deod
                const approveTx = await deodContract.approve(nefiPro, ethers.utils.parseEther(deodbuyValue))
                const aprRcpt = await approveTx.wait()
                //buy NEFI
                if (aprRcpt.status === 1) {

                  const buyTx = await nefiContract.buyNefi(ethers.utils.parseEther(deodbuyValue))
                  const buyRcpt = await buyTx.wait()

                  if (buyRcpt.status === 1) {
                    const payload2 = {
                      "deodAmount": deodbuyValue,
                      "rate": getCurrentNefiPrice,
                      "nefiAmount": buyValue,
                      "transactionHash": buyRcpt.transactionHash
                    }
                    setLoading(true);
                    setloadText('Completing Transaction')
                    const regularpurchasesApi = `/nefi-pro/register-regular-purchase`
                    const resp = await axios.post(regularpurchasesApi, payload2, {
                      headers,
                    })
                    if (resp.status == 201 || resp.status == 200) {
                      toast.success('Transaction Successful')
                    } else {
                    }
                    setLoading(false);
                  }
                }
              } catch (error) {
                console.log(error)
                const priorityrejectApi = `/nefi-pro/trx-reject-regular-user`
                const payload = {
                  "status": "rejected",
                }
                const resp = await axios.post(priorityrejectApi, payload, {
                  headers,
                })
                toast.error('Transaction Rejected')
              }
            }
          }
        }
      } else {
        let canProceed = false; // Initial flag to control the loop
        let ispriorityCount = false; // Initial flag to control the loop
        // Loop until the API returns true
        while (!ispriorityCount) {
          try {
            setLoading(true);
            setloadText('Checking Status...');

            // Call your API to check the status
            const checkpurchaseStatusApi = `/nefi-pro/count-purchase`;
            const resp = await axios(checkpurchaseStatusApi, {
              headers,
            });

            console.log(resp.data.count);

            if (resp.data.count >= 20) {
              ispriorityCount = true; // Exit the loop when the response is true
            } else {
              console.log('Can\'t proceed yet, retrying in 5 seconds...');
              // Wait for 5 seconds before making the next call
              await new Promise(resolve => setTimeout(resolve, 5000));
            }
          } catch (error) {
            console.error('Error calling API:', error);
            setLoading(false);
            break; // Exit the loop if there's an error
          }
        }
        if (ispriorityCount) {
          const isPriority = await checkPriority()
          if (!isPriority) {
            while (!canProceed) {
              try {
                setLoading(true);
                setloadText('Transaction Queued...');

                // Call your API to check the status
                const checkregularStatusApi = `/nefi-pro/regular-user-status`;
                const resp = await axios(checkregularStatusApi, {
                  headers,
                });

                console.log(resp.data.canProceed);

                if (resp.data.canProceed) {
                  canProceed = true; // Exit the loop when the response is true
                } else {
                  console.log('Can\'t proceed yet, retrying in 5 seconds...');
                  // Wait for 5 seconds before making the next call
                  await new Promise(resolve => setTimeout(resolve, 5000));
                }
              } catch (error) {
                console.error('Error calling API:', error);
                setLoading(false);
                break; // Exit the loop if there's an error
              }
            }
            if (canProceed) {
              setLoading(true);
              setloadText('Transaction Processing')
              const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
              const signer = newProvider.getSigner();
              const currentaccount = await signer.getAddress();
              const address = currentaccount.toLowerCase();
              const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
              const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)
              try {
                // approve deod
                const approveTx = await deodContract.approve(nefiPro, ethers.utils.parseEther(deodbuyValue))
                const aprRcpt = await approveTx.wait()
                //buy NEFI
                if (aprRcpt.status === 1) {

                  const buyTx = await nefiContract.buyNefi(ethers.utils.parseEther(deodbuyValue))
                  const buyRcpt = await buyTx.wait()

                  if (buyRcpt.status === 1) {
                    const payload2 = {
                      "deodAmount": deodbuyValue,
                      "rate": getCurrentNefiPrice,
                      "nefiAmount": buyValue,
                      "transactionHash": buyRcpt.transactionHash
                    }
                    setLoading(true);
                    setloadText('Completing Transaction')
                    const regularpurchasesApi = `/nefi-pro/register-regular-purchase`
                    const resp = await axios.post(regularpurchasesApi, payload2, {
                      headers,
                    })
                    if (resp.status == 201 || resp.status == 200) {
                      toast.success('Transaction Successful')
                    } else {
                    }
                    setLoading(false);
                  }
                }
              } catch (error) {
                console.log(error)
                const priorityrejectApi = `/nefi-pro/trx-reject-regular-user`
                const payload = {
                  "status": "rejected",
                }
                const resp = await axios.post(priorityrejectApi, payload, {
                  headers,
                })
                toast.error('Transaction Rejected')
              }
            }
          }

        }
      }

    } catch (error) {
      console.log(error)
    }
    setLoading(false);
    startTimer()
    loadData()
  }
  const handleClaimNefi = async () => {
    // alert("Coming Soon")
    setLoading(true);
    try {

      // Claim NEFI
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
      const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)

      const claimTx = await nefiContract.claimTokens(ethers.utils.parseEther(claimValue))
      const claimRcpt = await claimTx.wait()

      if (claimRcpt.status === 1) {
        alert('Claim Success')
        await handleClaimAPI(claimRcpt.transactionHash)
        await loadData()
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);

  }
  const handleSellNefi = async () => {
    // alert("Coming Soon")
    try {
      setLoading(true);

      //Sell NEFI
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const nefiContract = new ethers.Contract(nefiPro, nefiProABI, signer)
      const deodContract = new ethers.Contract(deodToken, deodTokenABI, signer)

      const sellTx = await nefiContract.sellNefi(ethers.utils.parseEther(sellValue))
      const sellRcpt = await sellTx.wait()

      if (sellRcpt.status === 1) {
        await handleSellApi(sellRcpt.transactionHash)
        await loadData()
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);

  }
  const handleSwap = async () => {
    // alert('Coming Soon')
    debugger
    try {
      if (swapValue <= userNefiBalance) {

        setLoading(true);
        setloadText('Transaction Processing')
        const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = newProvider.getSigner();
        const currentaccount = await signer.getAddress();
        const address = currentaccount.toLowerCase();
        const nefiSwappercontract = new ethers.Contract(nefiSwapper, nefiSwapperABI, signer)
        const oldNefiContract = new ethers.Contract(nefiToken, nefiTokenABI, signer)

        //approve deod
        const approveTx = await oldNefiContract.approve(nefiSwapper, ethers.utils.parseEther(swapValue))
        const aprRcpt = await approveTx.wait()
        //buy NEFI
        if (aprRcpt.status === 1) {

          const buyTx = await nefiSwappercontract.swap(ethers.utils.parseEther(swapValue))
          const buyRcpt = await buyTx.wait()

          if (buyRcpt.status === 1) {

            alert('Swap success')
            // handleBuyApi(buyRcpt.transactionHash)
          }
          await loadData()
        }
      } else {
        alert('Insufficient Balance')
      }
    } catch (error) {
      console.log(error)
    }
    setloadText('')
    setLoading(false);
  }
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };
  const handleCopy = () => {
    let text = `https://token.nexdefi.ai/?referral=${address}`;
    text.trim();
    navigator.clipboard.writeText(text);
    alert('Referral Link Copied')
  };

  const checkPriority = async () => {
    try {
      const token = sessionStorage.getItem('token2')
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You can adjust this as needed
      };
      const api = `/check/check-priority`
      const payload = {
        "deodAmount": deodbuyValue,
        "rate": getCurrentNefiPrice,
        "nefiAmount": buyValue
      }
      const resp = await axios.post(api, payload, {
        headers,
      })
      // console.log(resp.data.isPriority)
      setpriority(resp.data.isPriority)
      return resp.data.isPriority
    } catch (error) {
      console.log(error)
    }
  }
  const checkPrioritycount = async () => {
    try {
      const token = sessionStorage.getItem('token2')
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You can adjust this as needed
      };
      const api = `/nefi-pro/count-purchase`
      const resp = await axios(api, {
        headers,
      })
      // console.log(resp.data.isPriority)
      setpriorityCount(resp.data.count)
      return resp.data.count
    } catch (error) {
      console.log(error)
    }
  }
  const priorityBuyApi = async (trxHash) => {
    const token = sessionStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const api = '/api/complete-purchase'
    const payload = {
      "deodAmount": deodbuyValue,
      "rate": getCurrentNefiPrice,
      "nefiAmount": buyValue,
      "transactionHash": trxHash
    }
    try {
      const resp = await axios.post(api, payload, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // window.location.reload(false);
          loadData()
          if (!priority) {
            startTimer()
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  }

  const checkregularUserStatus = async () => {
    if (!priority) {
      await new Promise(resolve =>
        setTimeout(resolve, 300000)
      ); // Waits for 1 minute
      return true;
    }
  }
  // State to hold remaining time in seconds
  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24)
    const remainingHours = hours % 24
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return {
      days,
      remainingHours,
      minutes,
      seconds,
    };
  };
  // useEffect to update the countdown
  const { days, remainingHours, minutes, seconds } = formatTimeLeft(timeLeft);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = targetDate - currentTime;

      // Stop the countdown when time runs out
      if (difference <= 0) {
        clearInterval(intervalId);
        setTimeLeft(0);
      } else {
        setTimeLeft(difference);
      }
    }, 1000);

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, [targetDate]);
  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setRefferal(auth?.user?.referralAddress);
      setAddress(auth?.user?.address);
      // handleGetStackingData();
    }
  }, [auth]);
  // console.log(priority,'priority')
  useEffect(() => {
    async function handleData() {
      await loadData();
    }
    // startTimer()
    handleData();
    // checkregularUserStatus()
  }, []);
  useEffect(() => {
    // Set up an interval to call the function every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(() => {

      getNefiPrice();

    }, 15000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   if (getLocalData === null) {
  //     navigate("/");
  //   }
  // }, [getLocalData]);

  // useEffect(() => {
  //   async function handleGetParaswapPrice() {
  //     if (totalToken == 0) {
  //       setUsdtPrice(0);
  //     } else {
  //       try {
  //         let x;
  //         x = totalToken * 10 ** 18;
  //         let z = new BigNumber(x);
  //         let a = z.toFixed();
  //         // const deodToken = (totalToken * 10 ** 18).toString();
  //         const Paraswap = `https://api.paraswap.io/prices/?srcToken=0xe77abb1e75d2913b2076dd16049992ffeaca5235&destToken=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&amount=${a}&srcDecimals=18&destDecimals=6&side=SELL&excludeDirectContractMethods=false&network=137&otherExchangePrices=true&partner=paraswap.io&userAddress=0x0000000000000000000000000000000000000000`;
  //         console.log("Paraswap", a);
  //         const getParaswapData = await axios.get(Paraswap);
  //         if (getParaswapData.status === 200) {
  //           let finalUSDTPrice = getParaswapData?.data?.priceRoute?.destAmount;
  //           finalUSDTPrice = (finalUSDTPrice / 10 ** 6).toFixed(2);
  //           console.log("finaldeod&&&&", finalUSDTPrice);
  //           setUsdtPrice(finalUSDTPrice);
  //         }
  //       } catch (error) {
  //         console.log("error in deod price paraswap api", error);
  //       }
  //     }
  //   }
  //   handleGetParaswapPrice();
  // }, [totalToken]);

  return (
    <UserPageSection content="content1">
      {token2 &&
        <div>
          {loading && <Loading loading={loading} text={loadText} />}
          <div className="row">
            {/* <div className="col-6">
            <button
              className="btn connect-btn mx-2 boobtn"
              // onClick={() => navigate("/stack-token")}
            >
              Stake Token
            </button>
          </div> */}
            <div className="col-12">
              <div className="mt-2 px-4 py-2 d-md-flex align-items-center justify-content-start" style={{ background: '#ffffff3b', borderRadius: '10px', color: 'white', minHeight: '60px' }}>
                <div className="bg-dark p-2 mx-2 my-2" style={{ borderRadius: '10px' }}>
                  Balance in Contract :
                </div>
                <div className="bg-dark p-2 mx-2 my-2" style={{ borderRadius: '10px' }}><strong>DEOD : </strong>{totalDeodStaked?.toFixed(2)}</div>
                <div className=" bg-dark p-2 mx-2 my-2" style={{ borderRadius: '10px' }}><strong>NEFI : </strong>{circulatingNefiSupply?.toFixed(2)}</div>
                <div className=" bg-dark p-2 mx-2 my-2" style={{ borderRadius: '10px' }}><strong>NEFI Claimed : </strong>{8400000 + +getTotalNefiClaimed?.toFixed(2)}</div>
              </div>
            </div>
            <div className="col-12"> 
              <div className="mt-2 px-4 py-2 d-flex align-items-center" style={{ background: '#ffffff3b', borderRadius: '10px', color: 'white', minHeight: '60px', fontWeight: '600' }}>
              <div className="p-2 me-2 mb-md-0 mb-2 d-flex align-items-center" style={{ background: '#0d6efd', borderRadius: '10px' }}>
                  NEFI Price in USD : <span className="mx-1">{nefiUsdPrice ? ` ${nefiUsdPrice.toFixed(3)} $` : <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass="mx-1"
                    strokeWidth='5'
                  />} </span>
                </div>
                <div className="p-2" style={{ background: '#0d6efd', borderRadius: '10px' }}>
                  Balance Amount Of NEFI To Be Claimed : <span className="mx-1">{((5600000 - +getTotalNefiClaimed)).toFixed(2)}</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mt-2 px-4 py-2 d-flex align-items-center" style={{ background: '#ffffff3b', borderRadius: '10px', color: 'white', minHeight: '60px', fontWeight: 'bold' }}>
                Referral Link : {`https://token.nexdefi.ai/?${start_and_end(address)}`} <i class="bi bi-copy hover-text mx-2" onClick={handleCopy}></i>
              </div>
            </div>

            {/* <div className="col-12">
              <div className="mt-3 px-4 py-2 d-flex align-items-center" style={{ background: '#ffffff3b', borderRadius: '10px', color: 'white', minHeight: '60px', fontWeight: 'bold' }}>
                BUYING OF NEFI TOKEN WILL START ON November 25th, 2024 7:00 AM UTC.
              </div>
            </div>
            <div className="col-12">
              <div className="mt-3 px-4 py-2 d-flex align-items-center justify-content-center" style={{ background: '#ffffff3b', borderRadius: '10px', color: 'white', minHeight: '60px', fontWeight: 'bold' }}>
                <div className="countdown-wrapper">
                  <div className="countdown-item">
                    {days}
                    <span>days</span>
                  </div>
                  <div className="countdown-item">
                    {remainingHours}
                    <span>hours</span>
                  </div>
                  <div className="countdown-item">
                    {minutes}
                    <span>minutes</span>
                  </div>
                  <div className="countdown-item">
                    {seconds}
                    <span>seconds</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-4">
              <div className="row my-4">
                <div className="col-12">
                  <div className="card cus-card-token-ad text-center" style={{ height: '100%', position: 'relative' }}>
                    {
                      timerLeft !== 0 &&
                      <div className="overlay">{timerLeft}s</div>
                    }
                    <div className="card-body">
                      <div className="text-start" style={{}}>
                        <h5>
                          BUY NEFI
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between my-3" style={{}}>
                        <div>
                          <h6>
                            Available Balance
                          </h6>
                        </div>
                        <div>
                          <h6 className=""><i class="bi bi-wallet mx-1"></i>:{deodBalance.toFixed(2)} DEOD</h6>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between" style={{ fontSize: `13px` }}>
                        <div>
                          Price:
                        </div>
                        <div>
                          1 NEFI = {getCurrentNefiPrice?.toFixed(3)} DEOD
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                      <div className="">
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>!</button>
                      </div>
                      <div className="w-75" >
                        <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="text" value={`Limit`} disabled />
                      </div>
                      <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>▼</button>
                      </div>
                    </div> */}
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>DEOD:</button>
                        </div>
                        <div className="w-75" >
                          <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="number" max={50000} min={0} placeholder="Enter DEOD Amt" value={deodbuyValue} onChange={handleUnitChangeBuy} />
                        </div>
                        {/* <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>+</button>
                      </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>NEFI:</button>
                        </div>
                        <div className="w-75">
                          <input className='w-100 input-sale' type="text" value={buyValue} disabled onChange={handlesubUnitChangeBuy} style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} />
                        </div>

                        {/* <div>
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {/* <p className="mx-1 hover-text" style={{cursor:"pointer",fontSize:'15px'}} onClick={()=>calculateBuyPercentage(25)}>25%</p> */}
                        {/* <p className="mx-1 hover-text" style={{cursor:"pointer",fontSize:'15px'}} onClick={()=>calculateBuyPercentage(50)}>50%</p> */}
                        <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateBuyPercentage(100)}>Max</p>

                      </div>
                      <div>
                        <button className="btn connect-btn w-100 rounded" style={{ fontWeight: '700', background: 'limegreen' }} onClick={handleBuyNefi}>BUY NOW </button>
                      </div>
                      <div className="mt-1">
                        <p className="text-warning text-end" style={{ fontSize: "12px" }}>* Prices are approximate , may vary somewhat.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="row my-4">
                <div className="col-12">
                  <div className="card cus-card-token-ad text-center" style={{ height: '100%', position: 'relative' }}>
                    {
                      timerLeft !== 0 &&
                      <div className="overlay">{timerLeft}s</div>
                    }
                    <div className="card-body">
                      {/* <div>
                      <h6 className="text-start">Available : {unclaimedNefiTokens.toFixed(3)} NEFI <img src='/assets/symbol-nefi.png' className="mx-1 img-fluid" style={{ height: '20px' }} alt="Deod" /></h6>
                    </div> */}
                      <div className="text-start" style={{}}>
                        <h5>
                          SELL NEFI
                        </h5>
                      </div>
                      <div className="d-flex justify-content-between my-3" style={{}}>
                        <div>
                          <h6>
                            Available Balance
                          </h6>
                        </div>
                        <div>

                          <h6 className=""><i class="bi bi-wallet mx-1"></i>:{unclaimedNefiTokens === 0 ? 0 : (unclaimedNefiTokens + 0.001).toFixed(3)} NEFI</h6>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between" style={{ fontSize: `13px` }}>
                        <div>
                          Price:
                        </div>
                        <div>
                          1 NEFI = {getCurrentNefiPrice?.toFixed(3)} DEOD
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                      <div className="">
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>!</button>
                      </div>
                      <div className="w-75" >
                        <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="text" value={`Limit`} disabled />
                      </div>
                      <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>▼</button>
                      </div>
                    </div> */}
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        {/* <div className="">
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>-</button>
                      </div> */}
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>NEFI:</button>
                        </div>
                        <div className="w-75" >
                          <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="number" placeholder="Enter NEFI Amt" value={sellValue} onChange={handleUnitChangeSell} />
                        </div>
                        {/* <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>+</button>
                      </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>DEOD:</button>
                        </div>
                        <div className="w-75">
                          <input className='w-100 input-sale' type="text" value={deodsellValue} onChange={handlesubUnitChangeSell} style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} />
                        </div>
                        {/* <div>
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateSellPercentage(25)}>25%</p>
                        <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateSellPercentage(50)}>50%</p>
                        <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateSellPercentage(100)}>Max</p>

                      </div>
                      <div>
                        <button className="btn connect-btn w-100 rounded" style={{ fontWeight: '700', background: 'red' }} onClick={handleSellNefi}>SELL NOW</button>
                      </div>
                      <div className="mt-1">
                        <p className="text-warning text-end" style={{ fontSize: "12px" }}>* Prices are approximate , may vary somewhat.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mt-4 mb-2">
                <div className="col-12">
                  <div className="card cus-card-token-ad text-center" style={{ height: '100%' }}>
                    <div className="card-body">

                      <div className="d-flex justify-content-between" style={{}}>
                        <div className="text-start" style={{}}>
                          <h5>
                            CLAIM NEFI
                          </h5>
                        </div>
                        <div>
                          <h6 className=""><i class="bi bi-wallet mx-1"></i>:{unclaimedNefiTokens === 0 ? 0 : (unclaimedNefiTokens + 0.001).toFixed(3)} NEFI</h6>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between" style={{ fontSize: `13px` }}>
                      <div>
                        Price:
                      </div>
                      <div>
                        1 NEFI = {getCurrentNefiPrice?.toFixed(3)} DEOD
                      </div>
                    </div> */}
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                      <div className="">
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>!</button>
                      </div>
                      <div className="w-75" >
                        <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="text" value={`Limit`} disabled />
                      </div>
                      <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>▼</button>
                      </div>
                    </div> */}
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>NEFI:</button>
                        </div>
                        <div className="w-75" >
                          <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="number" placeholder="Enter NEFI Amt" value={claimValue} onChange={handleUnitChangeClaim} />
                        </div>
                        {/* <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>+</button>
                      </div> */}
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                    <div className="">
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>DEOD:</button>
                  </div>
                      <div className="w-75">
                        <input className='w-100 input-sale' type="text" value={deodclaimValue} onChange={handlesubUnitChangeClaim} style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} />
                      </div>
                      <div>
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div>
                    </div> */}
                      {/* <div className="d-flex align-items-center justify-content-end">
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(25)}>25%</p>
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(50)}>50%</p>
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(100)}>Max</p>

                    </div> */}
                      <div>
                        <button className="btn connect-btn w-100 rounded" style={{ fontWeight: '700' }} onClick={handleClaimNefi}>CLAIM NOW</button>
                      </div>
                      {/* <div className="mt-1">
                      <p className="text-warning text-end" style={{ fontSize: "12px" }}>* Prices are approximate , may vary somewhat.</p>
                    </div> */}
                    </div>

                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <div className="card cus-card-token-ad text-center" style={{ height: '100%' }}>
                    <div className="card-body">
                      <div className="text-start ">
                        <h5>
                          SWAP NEFI (OLD)
                        </h5>

                      </div>
                      {/* <div className="d-flex justify-content-between my-3" style={{}}>
                      <div>
                        <h6>
                          Available Balance
                        </h6>
                      </div>
                      <div>
                        <h6 className=""><i class="bi bi-wallet mx-1"></i>:{(unclaimedNefiTokens+0.001).toFixed(3)} NEFI</h6>
                      </div>
                    </div> */}
                      {/* <div className="d-flex justify-content-between" style={{ fontSize: `13px` }}>
                      <div>
                        Price:
                      </div>
                      <div>
                        1 NEFI = {getCurrentNefiPrice?.toFixed(3)} DEOD
                      </div>
                    </div> */}
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                      <div className="">
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>!</button>
                      </div>
                      <div className="w-75" >
                        <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="text" value={`Limit`} disabled />
                      </div>
                      <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>▼</button>
                      </div>
                    </div> */}
                      <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                        <div className="">
                          <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>NEFI:</button>
                        </div>
                        <div className="w-75" >
                          <input className='w-100' style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} onWheel={(e) => e.target.blur()} type="number" placeholder="Enter NEFI Amt" value={swapValue} onChange={handleUnitChangeSwap} />
                        </div>
                        {/* <div>
                        <button className="px-2" style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }}>+</button>
                      </div> */}
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{ background: '#232323', borderRadius: '10px' }}>
                    <div className="">
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>DEOD:</button>
                  </div>
                      <div className="w-75">
                        <input className='w-100 input-sale' type="text" value={deodclaimValue} onChange={handlesubUnitChangeClaim} style={{ background: '#232323', border: 'none', color: 'white', textAlign: 'center' }} />
                      </div>
                      <div>
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div>
                    </div> */}
                      {/* <div className="d-flex align-items-center justify-content-end">
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(25)}>25%</p>
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(50)}>50%</p>
                      <p className="mx-1 hover-text" style={{ cursor: "pointer", fontSize: '15px' }} onClick={() => calculateClaimPercentage(100)}>Max</p>

                    </div> */}
                      <div style={{ position: 'relative' }}>
                        <button className="btn connect-btn w-100 rounded" style={{ fontWeight: '700' }} onClick={handleSwap}>SWAP </button>
                        <div className="" style={{ position: 'absolute', bottom: "-30px", left: '-4px', fontSize: '14px' }}>
                          <i class="bi bi-wallet mx-1"></i>:{(userNefiBalance).toFixed(3)} NEFI ( OLD )
                        </div>
                      </div>
                      {/* <div className="mt-1">
                      <p className="text-warning text-end" style={{ fontSize: "12px" }}>* Prices are approximate , may vary somewhat.</p>
                    </div> */}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="row justify-content-center my-4">
                <div className="col-12 col-md-4">
                  <div className="card cus-card-token-ad text-center">
                    <div className="card-body">
                      <h6>Total Paid DEOD</h6>
                    </div>
                    {/* <p className="mt-3">
                    {(+finalTotalAmount - +totalToken).toFixed(2)}
                  </p> */}
                    <p className="mt-3">
                      {deodStaked.toFixed(3)}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card cus-card-token-ad text-center">
                    <div className="card-body">
                      <h6>Total Purchased NEFI</h6>
                    </div>
                    <p className="mt-1">{usertotalbuy.toFixed(3)}</p>
                    {/* <p className="mt-1">
                    <span>
                      <img src='/assets/symbol-nefi.png' className="mx-1 img-fluid" style={{ height: '28px' }} alt="Deod" />
                    </span>
                    {usertotalbuy}
                    {"  "}NEFI =
                    <span>
                      <img src={deodLogo} className="deod-logo" alt="USDT" />
                    </span>{" "}
                    {(usertotalbuy*getCurrentNefiPrice).toFixed(3)} 
                  </p> */}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card cus-card-token-ad text-center">
                    <div className="card-body">
                      <h6>Referral Earnings NEFI</h6>
                    </div>
                    <p className="mt-3">{referralRewards}</p>
                  </div>
                </div>
                {/* <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Referral Earning</h6>
              </div>
              <p className="mt-3">{refferalEarn}</p>
           
            </div>
          </div> */}
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Total Claimed NEFI </h6>
                </div>
                <p className="mt-3">{claimedNefiTokens}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Available Balance NEFI</h6>
                </div>
                <p className="mt-3">{unclaimedNefiTokens.toFixed(3)}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Total Withdrawn DEOD</h6>
                </div>
                <p className="mt-3">{userDeodReceived.toFixed(3)}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card cus-card-token-ad text-center">
                <div className="card-body">
                  <h6>Total NEFI SWAPPED </h6>
                </div>
                <p className="mt-3">{usernewNefiBalance}</p>
              </div>
            </div>

            <div className="col-12 ">
              {/* <div className="card cus-card-token-ad text-center"> */}
              <p className="mt-3 text-warning text-end" style={{ fontSize: '15px' }}>*Note : A user can withdraw only upto 2x the amount of DEOD they have Paid</p>
              {/* </div> */}
            </div>
          </div>
        </div>}
      {!token2 &&
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '60vh' }}>
          <button className="register-btn" onClick={() => navigate("/register")}>
            Register for New NEFI Token
          </button>
        </div>
      }
    </UserPageSection>
  );
};

export default UserDashboard2;
